.resize-handle[data-v-183b02e6] {
  display: block;
  position: absolute;
  cursor: col-resize;
  width: 7px;
  right: 0px;
  height: 20px;
  top: 25px;
  border-right: 2px solid #ccc;
}
.resize-handle[data-v-183b02e6]:hover {
  border-color: #ccc;
}
.resize-handle.active[data-v-183b02e6] {
  border-color: #517ea5;
}
table[data-v-183b02e6] {
  width: 100%;
  overflow: auto;
}
table th[data-v-183b02e6]{
  position: relative;
}
table th span[data-v-183b02e6], table td span[data-v-183b02e6] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
}
.tablecell[data-v-183b02e6] {
  border: none;
}
.tablecell.compact[data-v-183b02e6] {
  border-top: 1px solid rgba(224, 224, 224, 1);
  border-bottom: none;
}
.speed-action[data-v-183b02e6] {
  position:fixed;
  right: 10px;
  bottom: 10px;
}
.backdrop[data-v-183b02e6] {
  z-index: 1000;
}