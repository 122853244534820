.MuiTableCell-stickyHeader {
  z-index: inherit !important;
}

.MuiTableCell-body {
  word-break: break-all;
  padding-top: 12px;
  padding-bottom: 12px;
}

.MuiTableCell-root {
  font-weight: 500;
  font-size: 14px;
}

#events-menu a {
  margin-right: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
  transition: all 0.2s ease-in-out;
}
#events-menu a:hover {
  color: rgb(20, 20, 60) !important;
}
#events-menu a.active-link {
  color: rgb(20, 20, 60) !important;
}
