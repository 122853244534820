table[data-v-ff9c9adf] {
  width: 100%;
  overflow: auto;
}
table th[data-v-ff9c9adf] {
  position: relative;
}
table th span[data-v-ff9c9adf],
table td span[data-v-ff9c9adf] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}
.tree-vertical-line[data-v-ff9c9adf] {
  width: 20px;
  height: 1px;
  background: rgb(200, 200, 200);
  margin-right: 5px;
}
.span-dangerous[data-v-ff9c9adf] {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
