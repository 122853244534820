body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.form-control {
  margin-top: 10px !important;
  margin-bottom: 0px !important;
}
.form-control div {
  height: 64px;
  border: 1px solid #8b8da7;
  box-sizing: border-box;
  border-radius: 8px;
}
.form-control input {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 76.8%;
  color: #8b8da7;
}
.form-control fieldset {
  transition: all 0.2s ease-in;
}

header button {
  color: #bdc6f5 !important;
}