main[data-v-12043483] {
  max-width: initial;
  display: flex;
  width: 100vw;
  height: 100vh;
  padding: 0 0;
}
main #main-back[data-v-12043483] {
  height: 100vh;
  width: 100vw;
  background-size: 100% 100% !important;
  position: fixed;
  z-index: -1;
}
main .paper[data-v-12043483] {
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  background: #fff;
  border: 1px solid #141d70;
  box-sizing: border-box;
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 48px 24px;
  width: 512px;
}
main .paper svg[data-v-12043483] {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
main .paper h1[data-v-12043483] {
  margin-top: 20px;
  font-family: Montserrat;
  font-style: italic;
  font-weight: bold;
  font-size: 36px;
  line-height: 76.8%;
  text-align: center;
  letter-spacing: -0.840718px;
  text-transform: uppercase;
  color: #1c70bd;
}
main .paper h6[data-v-12043483] {
  margin-top: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 130%;
  text-align: center;
  color: #2d2e3b;
}
main .paper label[data-v-12043483] {
  display: block;
  margin-top: 24px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 76.8%;
  text-transform: uppercase;
  color: #2d2e3b;
}
main .paper form.form[data-v-12043483] {
  margin-top: 66px;
}
main .paper button[data-v-12043483] {
  margin-top: 88px;
  height: 64px;
  background: #1c70bd;
  border: 1px solid #1026ef;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 76.8%;
  text-transform: uppercase;
  color: #fff;
}
main .paper #forgot-pass[data-v-12043483] {
  margin-top: 18px;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 76.8%;
  text-align: center;
  color: #8b8da7;
}
main .paper #forgot-pass a[data-v-12043483] {
  color: #8b8da7;
}
