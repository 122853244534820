.appbar-navbar {
  background: #1c70bd !important;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.13), 0px 14px 35px -8px rgba(0, 0, 0, 0.05) !important;
}
.appbar-navbar header button {
  color: #bdc6f5 !important;
}
.appbar-navbar form div, .appbar-navbar form label {
  color: white !important;
}
.appbar-navbar form fieldset {
  border-color: white !important;
}
.appbar-navbar form svg {
  fill: white;
}
.appbar-navbar h6 {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.appbar-navbar h6 .title {
  margin-right: 50px;
  font-family: Montserrat;
  font-style: italic;
  font-weight: bold;
  font-size: 22px;
  line-height: 76.8%;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  color: #fff;
}
.appbar-navbar h6 svg {
  margin-right: 7px;
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
  width: 18px;
  height: 41px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.appbar-navbar h6 a, .appbar-navbar h6 .events-button {
  margin-right: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 76.8%;
  text-transform: uppercase;
  text-decoration: none;
  color: #bdc6f5;
  transition: all 0.2s ease-in-out;
}
.appbar-navbar h6 a:hover, .appbar-navbar h6 .events-button:hover {
  color: white !important;
}
.appbar-navbar h6 a.active-link {
  color: white;
}
.appbar-navbar h6 button {
  color: #bdc6f5;
}
.MuiInputBase-formControl {
  color: white;
}
